const mockData = {
  cardList: [
    {
      key: 'srze',
      label: '收入总额（运费 + 代采费）',
      value: 235665.30
    },
    {
      key: 'fhtyf',
      label: '复核退运费',
      value: 2555
    },
    {
      key: 'fhtdcf',
      label: '复核退代采费',
      value: 2635.8
    },
    {
      key: 'ptthtyf',
      label: '平台退货退运费',
      value: 56248.56
    },
    {
      key: 'ptthtdcf',
      label: '平台退货退代采费',
      value: 235665.65
    },
    {
      key: 'yfjs',
      label: '运费结算（复核金额）',
      value: 235665.2
    },
    {
      key: 'dcfjs',
      label: '代采费结算（复核金额）',
      value: 235665
    },
    {
      key: 'shtk',
      label: '售后退款',
      value: 55.30
    },
    {
      key: 'qtkk',
      label: '其他扣款',
      value: 0
    },
    {
      key: 'jsje',
      label: '结算金额',
      value: 1056604
    }
  ],
  tableList: {
    page: 1,
    pageSize: 10,
    total: 11,
    list: [
      {
        id: '111',
        jzrq: '2023-10-20',
        jzje: '20.30',
        ptfwf: '0.5',
        qtkk: '0',
        kkrq: '2023-12-21',
        shtk: '0.2',
        tkrq: '2023-12-22',
        jsje: '19.52',
        jsr: '2023-12-25',
        pttyf: '0',
        pttdcf: '0.12',
        pttkrq: '2023-12-21',
        fhtyf: '0',
        fhtdcf: '0.12',
        fhr: '2023-12-23',
        yfsr: '1.35',
        dcsr: '1.2',
        ghr: '2023-12-21',
        cscs: 2
      },
      {
        id: '222',
        jzrq: '2023-10-20',
        jzje: '20.30',
        ptfwf: '0.5',
        qtkk: '0',
        kkrq: '2023-12-21',
        shtk: '0.2',
        tkrq: '2023-12-22',
        jsje: '19.52',
        jsr: '2023-12-25',
        pttyf: '0',
        pttdcf: '0.12',
        pttkrq: '2023-12-21',
        fhtyf: '0',
        fhtdcf: '0.12',
        fhr: '2023-12-23',
        yfsr: '1.35',
        dcsr: '1.2',
        ghr: '2023-12-21',
        cscs: 2
      },
    ]
  }
}

export default mockData;
